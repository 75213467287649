import styled, { css } from 'styled-components'
import { copyHolderPauseStyles } from 'styles/copy-holder-pause-styles'
import { copyHolderWrapperStyles } from 'styles/copy-holder-wrapper.styles'

export const Container = styled.div`
  padding: 0 2rem;
`

export const PgWrapper = styled.div`
  padding-top: 1.375rem;
  position: relative;
  ${({ theme }) => theme.typography.body1};
  margin-bottom: 3rem;
`

export const PgWrapperInside = styled.div`
  max-width: 600px;
  margin: 0 auto;
`

export const StatsWrapper = styled.div``

export const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 0.375rem;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background.default};
  overflow: hidden;
`

interface ProgressProps {
  progress: number
  forDuration: boolean
}

export const Progress = styled.div.attrs<ProgressProps>((props) => ({
  style: {
    width: `${props.progress}%`,
  },
}))<ProgressProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main};
`

// transition: all ${({ forDuration }) => (forDuration ? AUTO_PAUSE_CHECK_INTERVAL : 200)}ms linear;

export const DurationWrapper = styled.div`
  user-select: none;
  display: flex;
  justify-content: space-between;
`
export const OneMinute = styled.div<{ isDisabled: boolean }>`
  margin-right: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 0.5rem;
  }

  .pg-action-one-minute {
    border: 1px solid ${({ theme }) => theme.palette.text.primary};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    font-size: 0.75rem;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
`

export const PgHeadingWrapper = styled.div`
  h1 {
    display: inline;
    ${({ theme }) => theme.typography.body1}
  }
`

export const CopyHolderWrapper = styled.div`
  ${copyHolderWrapperStyles};

  border: 1px solid ${({ theme }) => theme.palette.success.light};
  box-shadow: 0 0 2px ${({ theme }) => theme.palette.success.light};
`

export const PauseNotification = styled.div`
  ${copyHolderPauseStyles};
  font-size: 125%;

  ${({ theme }) => css`
    ${theme.breakpoints.down.sm} {
      font-size: 1.5rem;
    }
  `}
`

export const PgHeading = styled.span``

export const PgDurationDropdown = styled.select`
  display: block;
  font-size: 1rem;
  padding: 0.1rem 2rem 0.1rem 0.5rem;
  margin-bottom: -5px;
  border: 1px solid transparent;
  background-color: transparent;
  border-radius: ${({ theme }) => theme.borderRadius[3]};
  color: ${({ theme }) => theme.palette.text.primary};
  /* Remove default arrow styling */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Custom arrow icon (using an inline SVG) */
  background-image: ${({ theme }) => {
    // Use the theme's text color or fallback to currentColor if undefined
    const color = theme.palette.text.primary || 'currentColor'
    // Encode the color to make it URL-safe
    const encodedColor = encodeURIComponent(color)
    return `url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22${encodedColor}%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20class%3D%22lucide%20lucide-chevron-down%22%3E%3Cpath%20d%3D%22m6%209%206%206%206-6%22/%3E%3C/svg%3E')`
  }};
  background-repeat: no-repeat;
  background-position: right 0.75rem top 60%;
  background-size: 1rem 1rem;

  /* Change hover cursor only when not disabled */
  &:not(:disabled):hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.palette.border.light};
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.palette.border.light};
  }

  /* When disabled, show not-allowed cursor and use theme's disabled colors */
  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.palette.disabled.contrastText};
    border-color: ${({ theme }) => theme.palette.disabled.border};
  }

  /* Style the options to match the theme */
  option {
    background-color: ${({ theme }) => theme.palette.background.paper};
    color: ${({ theme }) => theme.palette.text.primary};
  }
`

export const PgDurationDropdownWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: auto;
  margin-left: 1rem;
  margin-bottom: -8px !important;
`
